.table-container {
  max-height: 400px; /* Set the desired height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.my-table {
  border-collapse: collapse !important;
  color: black;
  font-weight: bold !important;
  font-size: 16px !important;
}

.my-table th,
.my-table td {
  border: none !important;
}
.transparent-table {
  background-color: transparent !important;
}
.main__chatcontent {
    flex-grow: 1;
    padding: 20px 40px;
    max-width: 100%;
    border-right: 1px solid #ebe7fb;
}

.content__header {
    padding-bottom: 15px;
    border-bottom: 1px solid #ebe7fb;
}

.current-chatting-user {
    display: flex;
    align-items: center;
}

.current-chatting-user p {
    margin: 0;
    font-weight: 600;
}

.content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings .btn-nobg {
    color: #000;
}

.content__body {
    max-height: calc(100vh - calc(100vh / 2));
    overflow: auto;
    overflow-x: hidden;
}

.chat__item {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: right;
    animation-name: showIn;
    animation-duration: 0.2s;
    /* or: Xms */
    animation-iteration-count: 1;
    animation-direction: normal;
    /* or: normal */
    animation-timing-function: cubic-bezier(0.88,
            0.19,
            0.37,
            1.11);
    /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    animation-fill-mode: both;
    /* or: backwards, both, none */
    animation-delay: 0.2s;
    /* or: Xms */
}

@keyframes showIn {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.chat__item .avatar {
    margin-right: 0px;
    margin-left: 20px;
    background: #fff;
    padding: 1px;
}

.chat__item__content {
    background-color: #4462ff;
    color: #fff;
    padding: 15px;
    border-radius: 10px 10px 0 10px;
    max-width: 50%;

}

.chat__item__content .chat__meta {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
}

.scrollView::-webkit-scrollbar {
    width: '6px';
    border-radius: 10px;
}

.scrollView::-webkit-scrollbar-track {
    background-color: hsla(0,0%,80%,.3);
}

.scrollView::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: hsla(0, 0%, 80%, .5);
    box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

.chat__item__content .chat__meta span {
    font-size: 14px;
    color: #8693d3;
    user-select: none;
}

.chat__msg {
    user-select: none;
}

.chat__item.other {
    flex-direction: row-reverse;
    transform-origin: left;
}

.chat__item.other .chat__item__content {
    background-color: aliceblue;
    color: #000;
    border-radius: 10px 10px 10px 0;
    max-width: 50%;
}

.chat__item.other .avatar {
    margin-right: 20px;
    margin-left: 0px;
}

.chat__item.other .chat__item__content .chat__meta span {
    color: #d1d1d1;
}

.content__footer {
    padding-top: 30px;
}

.sendNewMessage {
    background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
}

.sendNewMessage button {
    width: 36px;
    height: 36px;
    background-color: #ecefff;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #4665ff;
    padding: 0;
    border-radius: 5px;
    line-height: 36px;
    transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button:hover {
    transform: scale(1.2);
}

.sendNewMessage button i {
    display: block;
}

.sendNewMessage input {
    flex-grow: 1;
    padding: 0 15px;
    background-color: transparent;
    border: none;
    outline: none;
}

#sendMsgBtn {
    background-color: #3b5bfe;
    color: #fff;
}


.main__chatlist {
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
}

.chatlist__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-nobg {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
    color: #dad9dd;
    outline: none;
}

.search_wrap {
    background-color: #e6e5ea;
    border-radius: 5px;
}

.search_wrap input {
    background-color: transparent;
    border: none;
    padding: 15px 15px;
    outline: none;
    width: 80%;
    padding-right: 0;
}

.search-btn {
    height: 46px;
    border: none;
    background-color: transparent;
    outline: none;
    width: 20%;
    cursor: pointer;
    font-size: 20px;
}

.chatlist__items {
    margin-top: 30px;
    overflow: auto;
    max-height: calc(100vh - calc(100vh / 2));
}

.chatlist__item {
    display: flex;
    border-bottom: 1px solid #ebe7fb;
    padding-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
    transform: scale(0);
    animation-name: showIn;
    animation-duration: 0.2s;
    /* or: Xms */
    animation-iteration-count: 1;
    animation-direction: normal;
    /* or: normal */
    animation-timing-function: cubic-bezier(0.88,
            0.19,
            0.37,
            1.11);
    /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    animation-fill-mode: both;
    /* or: backwards, both, none */
    animation-delay: 0.1s;
    /* or: Xms */
}

@keyframes showIn {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.chatlist__item:first-child {
    margin-top: 0;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    margin-right: 20px;
    position: relative;
}

.avatar img {
    max-width: 100%;
    object-fit: cover;
}

.chatlist__item .userMeta p {
    margin: 0;
    padding: 0;
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.chatlist__item .userMeta span {
    margin: 0;
    padding: 0;
    color: #ceccd3;
    font-weight: 400;
    font-size: 12px;
    display: block;
}

.chatlist__item:hover,
.chatlist__item.active {
    background: #fff;

    border-radius: 10px;
}

.isOnline {
    position: absolute;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ddd;
    border-radius: 50%;
    border: 2px solid #fff;
}

.isOnline.active {
    background-color: tomato;
}

.avatar-img {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
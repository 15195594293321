

  .tab-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 400px;

    

  }
  
  .tab-header {
    display: flex;
    justify-content: space-between;
    background-color: #EEF0F1;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: #d31616;
  }
  
  .tab-item {
    padding: 0px 0px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin: 0.5rem;
    padding: 1rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;

color: #4267B2;

  }
  
  .tab-item.active {
   
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    margin: 0.5rem;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;

    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
 
box-shadow: #FFFFFF;
color: #4267B2;


    
  }
  
  .tab-content {
    padding: 20px;
  }

 
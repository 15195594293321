.hover-container {
    position: relative;
    /* Add any other styles for the container */
  }
  
  .hover-text {
    position: absolute;
    top: -70%;
   border-radius: 5px;
    left: 0%;
    text-align: center;
    padding: 2px;
    font-size: 10px;
    /* Add any other styles for the hover text */
  }

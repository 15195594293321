.tabstyle .nav-link.active,
.tabstyle .nav-link:hover,
.tabstyle .nav-link:focus {
    color: #3868fb;
    background-color: papayawhip;
}

.tabstyle .nav-link {
    border-bottom: 2px solid rgb(170, 163, 163);
    color: #000000;
    font-weight: 600;
}
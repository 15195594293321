/* YourComponent.css */

.hover-container {
    /* Add any other styles you want for the container */
    position: relative;
  }
  
  .hover-text {
    display: none;
    /* Add any other styles you want for the hover text */
    position: absolute;
    top: 0;
    left: 0;
    background-color: none;
    padding: 5px;
    border: 1px none;
    font-size: 10px !important;
    text-align: center;
  }
  
  .hover-container:hover .hover-text {
    display: block;
    /* Add any other styles for the hover text when hovering over the container */
  }
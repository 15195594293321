.toggle-switch {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 50px;
  background-color:#eef1ee;
  border-radius: 10px;
  cursor: pointer;
  outline: 2px solid transparent;
}


.toggle-switch-inner {
  position: absolute;
  top: 7px;
  left: 12px;
  width: 40%;
  height: 33px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  transition: transform 0.3s;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.15);
 
}


.toggle-switch-text {
  position: absolute;
  top: 50%;
  font-size: 18px;
  font-weight: bold;
  color:rgba(0, 0, 0, 0.6);
  pointer-events: none;
  transform: translate(-50%, -50%);
  

  
}







.toggle-switch-text.on {
  left: 75%; /* Position "On" text inside the inner toggle */
}

.toggle-switch-text.off {
  left: 25%; /* Position "Off" text inside the background of the toggle button */
}

.toggle-switch.on .toggle-switch-inner {
  transform: translateX(110%); /* Slide the inner toggle to the right for "On" state */
  position: absolute;
  top: 7px;
  left: 12px;
  width: 40%;
  height: 33px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  transition: transform 0.3s;
  
  
}

.toggle-switch.off .toggle-switch-inner {
  transform: translateX(0%); /* Slide the inner toggle to the left for "Off" state */
}
.toggle-switch.on  {

  position: relative;
  display: inline-block;
  width: 130px;
  height: 50px;
  background-color:#2bc156fe;
  border-radius: 10px;
  cursor: pointer;
  outline: 2px solid transparent;
}